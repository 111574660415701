<template>
  <div>
    <el-row class="mt-24">
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <div class="mb-10">
            <el-button
              type="primary"
              plain
              style="margin-right: 20px"
              class="ml-15"
              @click="openCoupon(chosen_coupons)"
              >{{
                chosen_coupons && chosen_coupons.length >= 1
                  ? "重新选择优惠券"
                  : "添加优惠券"
              }}</el-button
            ><span class>首次登录用户送优惠券</span>
            <el-switch
              v-model="setPopup"
              active-color="#13ce66"
              class="ml-15"
              @change="couponShow"
            ></el-switch>
            <span class="ml-15 c-9"
              >开启以后，首次登录的用户展示添加的券</span
            >
            <el-table
              class="mt-24 member-table"
              :data="chosen_coupons"
              width="70%"
              v-if="chosen_coupons.length > 0"
            >
              <el-table-column
                :label="item.value"
                v-for="item in tableHeader"
                :key="item.key"
              >
                <template slot-scope="scope">
                  <el-avatar
                    v-if="item.key == 'imgUrl'"
                    :src="picsPath(scope.row.imgUrl)"
                    shape="square"
                    alt="加载失败"
                    :key="scope.row.imgUrl"
                  ></el-avatar>
                  <template v-else-if="item.key == 'couponType'">
                    {{
                      scope.row.type == 0
                        ? "折扣券"
                        : scope.row.type == 1
                        ? "代金券"
                        : scope.row.type == 2
                        ? "兑换券"
                        : scope.row.type == 3
                        ? "停车券"
                        : ""
                    }}
                  </template>
                  <template v-else-if="item.key == 'coupon'">
                    {{ couponType[scope.row.coupon] }}
                  </template>
                  <template v-else-if="item.key == 'drawCondition'">
                    {{ scope.row.drawCondition == 1 ? "免费领取" : "限领" }}
                  </template>
                  <template v-else>{{ scope.row[item.key] }}</template>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div class="flx-row ali-c">
                    <span
                      class="ml-10 tip-text-delete"
                      @click="deleteCoupon(scope.row.id)"
                      >删除</span
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 优惠券弹窗 -->
    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      @getSelectedList="getSelectedList"
      @getTableList="getTableList"
      :searchArr="searchArr"
      keyId="couponId"
    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import request from "@/utils/request";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import { getCouponList } from "@/api/maketing/coupons"; 
import {
  getTurn,
  queryLrNewUserCoupon,
  getAdList,
  operationTurn,
  getPopupsType,
  saveLrNewUserCoupon,
  lrNewUserCouponDelete,
} from "@/api/config_center/miniprogram_popup";
export default {
  components: {
    Pagination,
    UploadPics,
    DepartmentGoodsDialog,
  },
  data() {
    return {
      // 会员表格数据内容
      tableData: [],
      setPopup: false,
      chosen_coupons: [],
      tableHeader: [
        // 优惠券列表表头
        {
          value: "优惠券名称",
          key: "name",
        },
        {
          value: "优惠券类型",
          key: "coupon",
        },
        {
          value: "卡券有效期",
          key: "validTime",
        },
        {
          value: "领取条件",
          key: "drawCondition",
        },
        {
          value: "库存数量",
          key: "stock",
        },
        {
          value: "每人限领",
          key: "limitAmount",
        },
        {
          value: "领取数量",
          key: "received",
        },
      ],
      searchArr: [
        {
          name: "searchKey",
          value: "",
          placeholder: "请输入关键字",
        },
        {
          name: "status",
          value: "",
          placeholder: "请选择状态",
        },
      ],
      couponType: [
        "",
        "通用劵",
        "商品劵",
        "餐饮优惠券",
        "折扣券",
        "代金券",
        "停车券",
      ],
    };
  },
  mounted() {
    this.getType(); // 获取开关状态
    this.getList(); //获取已选中优惠券
  },
  methods: {
    getType() {
      getTurn().then((res) => {
        this.setPopup = res.data.body ? true : false;
      });
    },
    getList() {
      queryLrNewUserCoupon().then((res) => {
        this.chosen_coupons = res.data.body;
      });
    },
    //是否首登送券
    couponShow(e) {
      let showType = e ? 1 : 0;
      operationTurn(showType).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    // 删除已选优惠券
    deleteCoupon(idx) {
      lrNewUserCouponDelete(idx).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList(); //获取已选中优惠券
      });
    },
    //   获取已勾选优惠券
    getSelectedList(coupons, index) {
      let newArr = [];
      coupons.map((item) => {
        newArr.push(item.couponId);
      });
      saveLrNewUserCoupon(newArr).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getList(); //获取已选中优惠券
      });
    },
    getTableList(data) {
      //获取优惠券列表
      let params = { ...data };
      // params.status = params.couponStatus;
      // delete params.couponStatus;
      getCouponList(params).then((res) => {
        let data = this.$refs.GoodsDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
        //进行状态勾选
        data.toggleSelection();
      });
    },
    // 打开优惠券列表弹窗
    openCoupon(coupons) {
      let data = this.$refs.GoodsDialog;
      data.act_form.list = coupons;
      data.selected_temp = JSON.parse(JSON.stringify(coupons));
      data.showGoods = true;
      data.getGoods(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
